#videos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
}

.video-player {
    background-color: black;
    width: 100%;
    height: 200px;
}